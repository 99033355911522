body {
  font-size: 14px;
  font-family: arial, serif;
  margin: 0;
  padding: 0;
}

/* Parameter Selection Area */
.parameter-container {
  left: 0;
  right: 0;
  top: 0;
  margin-top: 0;
  padding: 1vh 1vw;
  background: #DCE3E4;
  color: #616876;
  height: 16vh;
  overflow-y: auto;
}

.gantt-container {
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 80vh;
}
.gantt-container.lg-container {
  min-height: 70vh;
  max-height: 89vh;
}

th {
  cursor: pointer;
}

/* ag-grid styles */
/* show vertical scroll*/
.ag-theme-balham .ag-body-viewport {
  overflow-y: scroll !important;
}
/* wrap text in header */
.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}
/* wrap text in cells*/
.ag-cell {
  white-space: normal !important;
}

/* construction start construction finish date text styles */
.text-right {
  text-align: right;
}
.blue-text {
  color: blue;
}
.black-text {
  color: black;
}
.orange-text {
  color: orange;
}